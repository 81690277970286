
<template>
  <div
    class="logo"
  >
    <div class="logo__container">
      <h1>Mara Yoga <span class="logo__subline">thoughtful yoga practice</span></h1>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    data() {
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  });
</script>
