
<template>
  <footer
    class="credits"
  >
    <div class="content-bit content-bit--right">
      Illustration: Moyleang Tan – Website: David Darx
    </div>
  </footer>
</template>
<script>
  import Vue from "vue";

  export default Vue.extend({
    data() {
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  });
</script>
